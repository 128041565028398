.modalForm {
    position: fixed;
    top: 30%;
    left: 50%;
    transform: translate(-50%, -30%);
    z-index: 20;
    min-width: 285px;
    width: 100%;
    max-height: 550px;
    padding: 20px;
    background-color: #FFF;
    background: #F4F7FD;
    box-shadow: 0px 10px 60px rgba(0, 0, 0, 0.1);
    border-radius: 20px;
    overflow-y: auto;
}

.modalForm .row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}