.search-list-enter {
  opacity: 0;
}

.search-list-enter-active {
  opacity: 1;
  transition: .5s opacity linear;
}

.search-list-exit {
  opacity: 1;
}

.search-list-exit-active {
  opacity: 0;
  transition: .5s opacity linear;
}