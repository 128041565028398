.row-enter {
  opacity: 0;
}

.row-enter-active {
  opacity: 1;
  transition: .5s opacity linear;
}

.row-exit {
  opacity: 1;
}

.row-exit-active {
  opacity: 0;
  transition: .5s opacity linear;
}