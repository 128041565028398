.data-list-enter {
  min-height: 0;
}

.data-list-enter-active {
  min-height: 100%;
  transition: .5s height linear;
}

.data-list-exit {
  min-height: 100%;
}
  
.data-list-exit-active {
  min-height: 0;
  transition: .5s height linear;
}