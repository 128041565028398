.item {
    display: flex;
    align-items: center;
    min-width: 40px;
    margin-bottom: 45px;
    font-family: Roboto, sans-serif;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    color: #A6AEC5;
    text-decoration: none;
    transition: .4s color linear;
}

.item.open {
    min-width: 150px;
}

.item:hover {
    color: #4e4b4b;
}

.item.active {
    color: #131313;
    cursor: default;
}

.iconBlock {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
}

.name {
    width: 100px;
    padding: 0;
    margin: 0 0 0 30px;
}