.contacts {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
}

.icon {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    margin-right: 15px;
}

.info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}

.value {
    font-family: Roboto, sans-serif;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #131313;
}