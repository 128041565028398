.parameterDisplayer {
    width: 100%;
    padding: 20px;
    font-family: Roboto;
    font-weight: bold;
    background-color: #FFF;
    box-shadow: 0px 10px 60px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
}

.param {
    margin-right: 5px;
    font-size: 24px;
    line-height: 21px;
    display: flex;
    align-items: center;
    color: #131313;
}

.param.failure {
    color: #FA5D54;
}

.status {
    font-size: 14px;
    line-height: 16px;
}

.status.success {
    color: #4775FE;
}

.status.failure {
    color: #FA5D54;
}

.paramBlock {
    display: flex;
    align-items: flex-end;
}