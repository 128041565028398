.nameView {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    height: 100%;
    font-family: 'Roboto', sans-serif;
}

.name {
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
}

.name:not(:last-child) {
    margin-bottom: 2px;
}

.subname {
    font-weight: 500;
    font-size: 13px;
    line-height: 15px;
    color: #A6AEC5;
}