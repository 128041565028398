.tableWrp {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.table {
    width: 100%;
    font-family: Roboto, sans-serif;
    font-weight: 500;
    border-spacing: 0;
}

.table th {
    max-width: 185px;
    font-size: 13px;
    line-height: 15px;
    color: #A6AEC5;
    text-align: start;
    padding-bottom: 10px;
}

.table tbody tr {
    cursor: pointer;
    background-color: transparent;
    transition: .6s background-color linear;
}

.table.whiteRow tbody tr {
    background-color: #FFF;
} 

.table td {
    max-width: 185px;
    font-size: 14px;
    line-height: 16px;
    color: #131313;
    text-align: start;
    background-color: transparent;
    box-shadow: 0px 10px 60px rgba(0, 0, 0, 0);
    transition: .6s all linear;
}

.table .spacer {
    height: 15px;
}

.table tbody tr:hover {
    background-color: #FFF;
}

.table tbody tr:hover td {
    box-shadow: 0px 10px 60px rgba(0, 0, 0, 0.1);
}

.table tbody td:first-child {
    border-bottom-left-radius: 50px;
    border-top-left-radius: 50px;
}

.table tbody td:last-of-type {
    border-bottom-right-radius: 50px;
    border-top-right-radius: 50px;
}

.headItem {
    display: flex;
    align-items: center;
    width: 100%;
}

.headItem p {
    margin-right: 5px;
}

.buttonPagination,
.buttonNumber {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 35px;
    height: 35px;
    color: #131313;
    border-radius: 50%;
    background-color: #FFF;
    border: 1px solid transparent;
    transition: .4s background-color linear;
    cursor: pointer;
}

.buttonRight {
    transform: rotate(-90deg);
}

.buttonPagination:disabled {
    background-color: #A6AEC5;
    color: #FFF;
    cursor: default;
}

.buttonleft {
    margin-right: 10px;
    transform: rotate(90deg);
    
}

.buttonNumber {
    margin-right: 10px;
}

.pagNumber {
    font-family: 'Roboto', sans-serif;
    font-weight: normal;
    font-size: 13px;
    line-height: 15px;
    color: #131313;
}

.buttonNumber:disabled {
    background-color: #4775FE;
    cursor: default;
}

.buttonNumber.separator {
   cursor: default;
}

.buttonNumber:disabled .pagNumber {
    color: #FFF;
}