.notification {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 10px;
    border-radius: 0 10px 10px 10px;
}

.iconType {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    margin-right: 20px;
    border-radius: 50%;
    background-color: #EBEEF6
}

.content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    flex-grow: 1;
    font-family: Roboto, sans-serif;
    font-weight: 500;
}

.notificationContent {
    width: 100%;
    margin-bottom: 5px;
    font-size: 14px;
    line-height: 16px;
    color: #131313;
}

.source {
    font-size: 13px;
    line-height: 15px;
    color: #A6AEC5;
}

.star, .eye {
    width: 20px;
    height: 20px;
    margin-right: 20px;
    border: none;
    outline: transparent;
    background-color: transparent;
    cursor: pointer;
}

.eye.small {
    margin-right: 10px;
}

.ckeckbox {
    margin-right: 10px;
}