.fade-fon-enter {
    opacity: 0;
}
  
.fade-fon-enter-active {
    opacity: 1;
    transition: opacity 300ms;
}
  
.fade-fon-exit {
    opacity: 1;
}
  
.fade-fon-exit-active {
    opacity: 0;
    transition: opacity 300ms;
}