.btnBlock {
    position: relative;
}

.btnInfo {
    padding: 3px;
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #131313;
    background-color: #EBEEF6;
    border-radius: 10px;
    border: 1px solid transparent;
    cursor: pointer;
}

.list {
    display: flex;
    flex-direction: column;
    padding: 15px;
    background: #FFFFFF;
    box-shadow: 0px 10px 60px rgba(0, 0, 0, 0.1);
    border-radius: 0 10px 10px 10px;
}

.listItem:not(:last-of-type) {
    margin-bottom: 10px;
}

.link {
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #4775FE;
    text-decoration: none;
    transition: .4s all linear;
}

.link:hover {
    text-decoration: underline;
}