.progressGraphWrp {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
}

.progressGraph {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    height: 270px;
}

.progressGraphNumbers {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 90%;
    margin-right: 15px;
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    font-size: 13px;
    line-height: 15px;
    color: #A6AEC5;
}

.diagrams {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: nowrap;
    width: 100%;
    height: 100%;
}

.diagramBlock {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: auto;
    height: 100%;
    margin-right: 15px;
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    font-size: 13px;
    line-height: 15px;
    color: #A6AEC5;
}

.diagram {
    position: relative;
    display: flex;
    align-items: flex-end;
    width: 15px;
    height: 90%;
    margin-bottom: 10px;
    background-color: #EBEEF6;
}

.scheduled {
    position: absolute;
    width: 100%;
    background-color: #A6AEC5;
}

.actual {
    position: absolute;
    width: 100%;
    background-color: #131313;
}

.progressMarkersBlock {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 285px;
    margin-bottom: 15px;
}

.progressMarkerBlock {
    display: flex;
    align-items: center;
}

.progressMarkerBlock:not(:last-of-type) {
    margin-right: 20px;
}

.progressType {
    width: 10px;
    height: 10px;
    margin-right: 5px;
    background-color: #EBEEF6;
}

.progressType.darkGrey {
    background-color: #A6AEC5;
}

.progressType.black {
    background-color: #131313;
}

.progressTypeName {
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    font-size: 13px;
    line-height: 15px;
    color: #A6AEC5;
}