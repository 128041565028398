.data-list-enter {
  transform: scale(0);
}

.data-list-enter-active {
  transform: scale(1);
  transition: .5s transform linear;
}

.data-list-exit {
  transform: scale(0);
}
  
.data-list-exit-active {
  transform: scale(0);
  transition: .5s transform linear;
}