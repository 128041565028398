.buttonFunctions {
    position: relative;
    width: 100%;
    font-family: Roboto, sans-serif;
    font-size: 14px;
    line-height: 16px;
}

.buttonFunctions {
    font-weight: 700;
}

.btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 40px;
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    color: #FFFFFF;
    border: 1px solid transparent;
    outline: transparent;
    background-color: transparent;
    border-radius: 25px;
    cursor: pointer;
    opacity: 1;
    transition: .4s opacity linear, .4s background-color linear;
}

.buttonList {
    position: absolute;
    z-index: 10;
    top: 120%;
    padding: 15px;
    background-color: #FFFFFF;
    box-shadow: 0px 10px 60px rgba(0, 0, 0, 0.1);
    border-radius: 10px 0px 10px 10px;
}

.buttonList li:not(:last-child) {
    margin-bottom: 10px;
}