.btn {
    display: flex;
    align-items: center;
    width: 100%;
    height: 40px;
    font-family: Roboto, sans-serif;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #FFFFFF;
    border: 1px solid transparent;
    outline: transparent;
    background-color: transparent;
    border-radius: 25px;
    cursor: pointer;
    opacity: 1;
    transition: .4s opacity linear, .4s background-color linear;
}

.btn:disabled {
    cursor: not-allowed;
}

.btn:hover {
    opacity: .8;
}

.btn:hover:disabled {
    opacity: 1;
}

.icon {
    margin-right: 10px;
}