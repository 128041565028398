.avatarBlock {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
}

.avatar {
    border-radius: 50%;
}

.circelAvatar {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 78%;
    height: 78%;
    font-family: Roboto, sans-serif;
    font-weight: 500;
    color: #FFFFFF;
    border-radius: 50%;
}