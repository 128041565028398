.radioLabel {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.radioInput {
    position: absolute;
    visibility: hidden;
    opacity: 0;
    width: 0;
    height: 0;
}

.radio {
    width: 15px;
    height: 15px;
    background-image: url("../../../../assets/svg/radio.svg");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    transition: .4s background-image linear;
}

.radioInput:checked + .radio {
    background-image: url("../../../../assets/svg/radio-checked.svg");
}

.text {
    margin-left: 10px;
    font-family: Roboto, sans-serif;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #A6AEC5;
}