.tagInputBlock {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
}

.tagItem {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 25px;
    padding: 5px 10px;
    margin-right: 10px;
    white-space: nowrap;
    color: #FFF;
    background-color: #4775FE;
    border-radius: 25px;
}

.btnBlock {
    margin-left: 5px;
}

.closeBtnBlock {
    transform: rotate(-45deg);
}

.listTags {
    position: absolute;
    top: 110%;
    left: 0;
    width: auto;
    max-height: 180px;
    padding: 15px;
    overflow-y: auto;
    background: #FFFFFF;
    box-shadow: 0px 10px 60px rgba(0, 0, 0, 0.1);
    border-radius: 0px 10px 10px 10px;
}

.listTags .tagItem:not(:last-child) {
    margin-bottom: 10px;
}

.listTags::-webkit-scrollbar {
    width: 0px;
    height: 0px;
}

.listTags::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0.2);
}

.listTags::-webkit-scrollbar-thumb {
    background: rgba(90, 90, 90);
}

.radio {
    width: 15px;
    height: 15px;
    margin-right: 10px;
    background-image: url("../../../../assets/svg/radio.svg");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    background-color: transparent;
    border: 1px solid transparent;
    transition: .4s background-image linear;
}

.radio.checked {
    background-image: url("../../../../assets/svg/radio-checked.svg");
}

.btnInfo {
    padding: 3px;
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #131313;
    background-color: #EBEEF6;
    border-radius: 10px;
    border: 1px solid transparent;
    cursor: pointer;
}