.tabsBlock {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px;
    background-color: #FFF;
    box-shadow: 0px 10px 60px rgba(0, 0, 0, 0.1);
    border-radius: 40px;
}

.tabItem {
    padding: 7px;
    font-family: Roboto, sans-serif;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    color: #131313;
    background-color: transparent;
    border: 1px solid transparent;
    border-radius: 20px;
    opacity: 1;
    transition: .4s background-color linear, .4s opacity linear;
    cursor: pointer;
}

.tabItem:hover {
    opacity: .6;
}

.tabItem.active {
    background-color: #4775FE;
    color: #FFF;
}