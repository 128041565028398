.dropdown-list-enter {
    opacity: 0;
  }
  .dropdown-list-enter-active {
    opacity: 1;
    transition: .5s opacity linear;
  }
  .dropdown-list-exit {
    opacity: 1;
  }
  .dropdown-list-exit-active {
    opacity: 0;
    transition: .5s opacity linear;
  }