.form {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 260px;
    width: 100%;
}

.logo {
    max-width: 160px;
    margin-bottom: 15px;
}

.title {
    margin-bottom: 15px;
    font-family: Roboto, sans-serif;
    font-weight: bold;
    text-align: center;
    color: #131313;
}