.main {
    display: flex;
    width: 100%;
}

.wrp {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 100px;
}