@import '../node_modules/react-big-calendar/lib/css/react-big-calendar.css';

@font-face {
  font-family: "Roboto";
  src: local('Roboto'), url("./assets/fonts/Roboto/Roboto-Bold.ttf") format("truetype");
  font-weight: bold, 700;
}

@font-face {
  font-family: "Roboto";
  src: local('Roboto'), url("./assets/fonts/Roboto/Roboto-Medium.ttf") format("truetype");
  font-weight: 500;
}

@font-face {
  font-family: 'icons';
  src: url('./assets/icons/font/icons.eot?11538989');
  src: url('./assets/icons/font/icons.eot?11538989#iefix') format('embedded-opentype'),
       url('./assets/icons/font/icons.woff2?11538989') format('woff2'),
       url('./assets/icons/font/icons.woff?11538989') format('woff'),
       url('./assets/icons/font/icons.ttf?11538989') format('truetype'),
       url('./assets/icons/font/icons.svg?11538989#icons') format('svg');
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'icons';
    src: url('../font/icons.svg?11538989#icons') format('svg');
  }
}
*/
[class^="icon-"]:before, [class*=" icon-"]:before {
  font-family: "icons";
  font-weight: normal;
  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.icon-vector-19:before { content: '\e800'; } /* '' */
.icon-vector-8:before { content: '\e80b'; } /* '' */
.icon-plus:before { content: '\e801'; } /* '' */
.icon-ads:before { content: '\e802'; } /* '' */
.icon-groups:before { content: '\e803'; } /* '' */
.icon-services:before { content: '\e804'; } /* '' */
.icon-clients:before { content: '\e805'; } /* '' */
.icon-workers:before { content: '\e806'; } /* '' */
.icon-settings:before { content: '\e807'; } /* '' */
.icon-email:before { content: '\e808'; } /* '' */
.icon-tel:before { content: '\e809'; } /* '' */
.icon-money:before { content: '\e80a'; } /* '' */
.icon-info:before { content: '\e80c'; } /* '' */
.icon-export-file:before { content: '\e80d'; } /* '' */
.icon-search:before { content: '\e80e'; } /* '' */
.icon-time:before { content: '\e80f'; } /* '' */
.icon-star-empty:before { content: '\e810'; } /* '' */
.icon-star:before { content: '\e811'; } /* '' */
.icon-sort-1:before { content: '\e812'; } /* '' */
.icon-sort-2:before { content: '\e813'; } /* '' */
.icon-notification:before { content: '\e814'; } /* '' */
.icon-messageicon:before { content: '\e815'; } /* '' */
.icon-info-all:before { content: '\e817'; } /* '' */
.icon-filter:before { content: '\e818'; } /* '' */
.icon-marker:before { content: '\e819'; } /* '' */
.icon-date:before { content: '\e81a'; } /* '' */
.icon-arrow:before { content: '\e81b'; } /* '' */
.icon-home:before { content: '\e81e'; } /* '' */
.icon-edit:before { content: '\e81f'; } /* '' */
.icon-delete:before { content: '\e820'; } /* '' */


body, html, #root {
  margin: 0;
  background-color: #F4F7FD;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

ul {
  list-style-type: none;
  padding: 0;
}

.type-primary {
  background-color: #4775FE;
}

.type-danger {
  background-color: #FA5D54;
}

.icon {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.rbc-calendar *, .rbc-calendar *:before, .rbc-calendar *:after {
  box-sizing: inherit;
  font-family: Roboto, sans-serif;
  font-weight: 500;
}

.rbc-toolbar button:active, .rbc-toolbar button.rbc-active {
  background-color: #EBEEF6;
  border-color: #adadad;
}

.rbc-off-range-bg {
  background: #EBEEF6;
}

.rbc-event, .rbc-day-slot .rbc-background-event {
  background-color: rgb(71, 117, 254);
}

.rbc-month-view, .rbc-time-view, .rbc-agenda-view {
  border-color: #5079eb;
  border-radius: 2px;
}

.rbc-today {
  background-color: #d3f1f2;
}
