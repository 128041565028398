.radioBlock {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: auto;
    padding: 10px 20px;
    background-color: #FFFFFF;
    border-radius: 50px;
}

.radioBlock.skeleton {
    height: 50px;
}

.radioBlock:not(:last-of-type) {
    margin-bottom: 10px;
}

.leftCol,
.rightCol {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    min-width: 150px;
}

.radioLabel {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.radioLabel:not(:last-child) {
    margin-right: 5px;
}

.radioInput {
    position: absolute;
    visibility: hidden;
    opacity: 0;
    width: 0;
    height: 0;
}

.radio {
    width: 15px;
    height: 15px;
    background-image: url("../../../../assets/svg/radio.svg");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    transition: .4s background-image linear;
}

.radioInput:checked + .radio {
    background-image: url("../../../../assets/svg/radio-checked.svg");
}