.btnInfo {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid transparent;
    outline: transparent;
    background-color: transparent;  
    opacity: 1;
    transition: .4s opacity linear;
    cursor: pointer;
}

.btnInfo:hover {
    opacity: .7;
} 

.btnBlock {
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}