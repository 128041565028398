.buttonNotifications {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

.buttonNotifications::after {
    content: "";
    position: absolute;
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background-color: #FA5D54;
    opacity: 0;
    visibility: hidden;
    transition: .4s opacity linear;
}

.buttonNotifications.active::after {
    opacity: 1;
    visibility: visible;
}

.buttonNotifications.nitification::after {
    bottom: 3px;
    right: 1px;
}

.buttonNotifications.message::after {
    bottom: 7px;
    right: -1px;
}