.firstButton, .secondButton {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50%;
    height: 40px;
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #FFFFFF;
    border: 1px solid transparent;
    cursor: pointer;
    opacity: 1;
    transition: .4s opacity linear;
}

.firstButton:hover, .secondButton:hover {
    opacity: .4;
}

.firstButton {
    border-right: 0.5px solid #FFF;
    border-radius: 20px 0 0 20px;
}

.secondButton {
    border-left: 0.5px solid #FFF;
    border-radius: 0 20px 20px 0;
}