.priceBlock {
    width: 100%;
}

.priceInfoBlock {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.nameBlock {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.name {
    margin-right: 5px;
    font-family: 'Roboto', sans-serif;
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    color: #A6AEC5;
}

.arrowBlock {
    width: 10px;
    height: 7px;
    transition: .4s transform linear;
}

.nameBlock.active .arrowBlock {
    transform: rotate(-180deg);
}

.price {
    font-family: 'Roboto', sans-serif;
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    text-align: right;
    color: #A6AEC5;
}

.priceItem {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #A6AEC5;
}

.priceItem:not(:last-of-type) {
    margin-bottom: 15px;
}

.priceList {
    padding-top: 15px;
}