.listFieldDrag {
    width: 100%;
}

.listItem {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 10px;
}

.dragButton {
    margin-top: 5px;
    cursor: pointer;
}

.dragActive {
    display: none;
}

.dragUp {
    animation: dragup ease 0.2s 1;
    animation-fill-mode: forwards;
}
  
.dragDown {
    animation: dragdown ease 0.2s 1;
    animation-fill-mode: forwards;  
}
  
@keyframes dragup {
    from {
        padding-top: 10px;
    }
    to {
        padding-top: 60px;
    }
}
  
@keyframes dragdown {
    from {
        padding-bottom: 10px;
        padding-top: 60px;
    }

    to {
        padding-bottom: 60px;
        padding-top: 10px;
    }
}