.switch {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80px;
    height: 40px;
    cursor: pointer;
  }
  
  /* Hide default HTML checkbox */
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  /* The slider */
.slider {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #FFF;
    box-shadow: 0px 10px 60px rgba(0, 0, 0, 0.1);
    border-radius: 40px;
}
  
.slider:before {
    content: "";
    position: absolute;
    height: 30px;
    width: 30px;
    right: 5px;
    bottom: 5px;
    background-color: #4775FE;
    border-radius: 50%;
    transition: .4s background-color linear, .4s transform linear;
}
  
input:checked + .slider:before {
    transform: translateX(-40px);
}

.status {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 58px;
    font-family: Roboto, sans-serif;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
}

.status .statusOff,
.status .statusOn {
    transition: .4s color linear;
}

.switch .statusOff {
    color: #FFF;
}

.switch .statusOn {
    color: #131313;
}

.switch.active .statusOn {
    color: #FFF;
}

.switch.active .statusOff {
    color: #131313;
}