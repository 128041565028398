.sidebar {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 20;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    padding: 30px 30px 45px;
    overflow: hidden;
    transition: .4s ease-in;
}

.sidebarList {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-left: 5px;
}

.logoBlock {
    display: flex;
    align-items: center;
}

.logo {
    min-width: 40px;
}

.logoOpacity {
    position: relative;
    top: 5px;
    margin-left: 15px;
    white-space: nowrap;
}

.barBlock {
    position: absolute;
}

.btnBlock {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    margin-top: auto;
}

.nav {
    margin-top: 70px;
}