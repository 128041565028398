.lessonBlock {
    display: flex;
    font-family: 'Roboto', sans-serif;
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    color: #131313;
}

.lessonBlock:not(:last-of-type) {
    margin-bottom: 15px;
}

.date {
    margin-right: 20px;
}

.iconDate {
    margin-right: 10px;
    color: #A6AEC5;
}

.nameLesson {
    margin-right: 20px;
}

.iconVisit {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background-image: url("../../../assets/svg/radio-checked.svg");
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-color: transparent;
}

.iconVisit.false {
    background: #FA5D54;
}