.emptyBlock {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    height: 100%;
}

.empty {
    width: 15px;
    height: 3px;
    background-color: #EBEEF6;
    border-radius: 20px;
}

.empty.black {
    background-color: #131313;
}