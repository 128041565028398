.message {
    position: fixed;
    z-index: 15;
    display: flex;
    align-items: center;
    width: 100%;
    padding: 10px 16px;
    background: #fff;
    border-radius: 2px;
    box-shadow: 0 3px 6px -4px #0000001f, 0 6px 16px #00000014, 0 9px 28px 8px #0000000d;
    pointer-events: all;
}

.message.top {
    top: 35px;
    left: 55%;
    transform: translateX(-55%);
}

.message.bottom {
    bottom: 35px;
    left: 55%;
    transform: translateX(-55%);
}

.message.left {
    top: 35px;
    left: 130px;
}

.message.right {
    top: 35px;
    right: 35px;
}

.iconBlock {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 5px;
}