.listFieldBlock {
    width: 100%;
}

.listField {
    width: auto;
}

.listItem {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: flex-end;
    width: auto;
}

.listItem:not(:last-of-type) {
    margin-bottom: 20px;
}

.rotate {
    transform: rotate(-45deg);
}