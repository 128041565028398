.radioBlock {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 40px;
    padding: 0 20px;
    background-color: #FFF;
    box-shadow: 0px 10px 60px rgba(0, 0, 0, 0.1);
    border-radius: 50px;
}

.radioBlock:not(:last-of-type) {
    margin-bottom: 10px;
}

.name {
    font-family: Roboto, sans-serif;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    color: #131313;
}