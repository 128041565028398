.infoListBlock {
    width: 100%;
}

.nameBlock {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    font-family: 'Roboto', sans-serif;
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    color: #131313;
}

.infoList {
    width: 100%;
    padding-top: 20px;
    font-family: 'Roboto', sans-serif;
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    text-align: right;
    color: #A6AEC5;
}

.infoItem {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.infoItem:not(:last-of-type) {
    margin-bottom: 15px;
}