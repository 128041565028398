.error-message-enter {
    opacity: 0;
}
  
.error-message-enter-active {
    opacity: 1;
    transition: opacity 300ms;
}
  
.error-message-exit {
    opacity: 1;
}

.error-message-exit-active {
    opacity: 0;
    transition: opacity 300ms;
}