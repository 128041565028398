.primary {
    background-color: #EBEEF6;
}

.fade-enter {
    opacity: 0;
}
  
.fade-enter-active {
    opacity: 1;
    transition: opacity 400ms;
}
  
.fade-exit {
    opacity: 1;
}
  
.fade-exit-active {
    opacity: 0;
    transition: opacity 400ms;
}