.fileFinder {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #A6AEC5;
    border-radius: 20px;
    /* box-shadow: 0px 10px 60px rgba(0, 0, 0, 0.1); */
    transition: .4s background-color linear;
    cursor: pointer;
    overflow: hidden;
}

.fileFinder.disabled {
    background-color: #EBEEF6;
    cursor: default;
}

.fileFinderImg {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
}