.breadcrumb {
    display: flex;
    align-items: flex-end;
    flex-flow: row wrap;
    width: 100%;
    font-family: Roboto, sans-serif;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    vertical-align: bottom;
    color: #131313;
}

.separator {
    margin: 0 10px;
}

.breadcrumb li:last-of-type {
    font-size: 32px;
    line-height: 28px;
}