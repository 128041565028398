.message-enter {
  opacity: 0;
}

.message-enter-active {
  opacity: 1;
  transition: opacity 400ms;
}

.message-exit {
  opacity: 1;
}

.message-exit-active {
  opacity: 0;
  transition: opacity 400ms;
}