.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 0 30px 0;
}

.leftCol, .rightCol {
    display: flex;
    align-items: center;
}

.leftCol {
    justify-content: flex-start;
}

.rightCol {
    justify-content: flex-end;
}

.goBackArrow {
    transform: rotate(90deg);
}

.menuBlock {
    position: relative;
    z-index: 5;
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    height: auto;
    background-color: transparent;
    border: 1px solid transparent;
    cursor: pointer;
}

.menu {
    position: absolute;
    top: 110%;
    right: 0;
    padding: 15px;
    background: #FFF;
    box-shadow: 0px 10px 60px rgba(0, 0, 0, 0.1);
    border-radius: 10px 0px 10px 10px;
}

.buttonExit {
    font-family: 'Roboto', sans-serif;
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    color: #000000;
    background-color: transparent;
    border: 1px solid transparent;
    cursor: pointer;
}