.data {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    min-height: calc(100vh - 105px);
    padding: 20px;
    background-color: #EBEEF6;
    border-radius: 20px 20px 0px 0px;
}