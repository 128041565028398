.circleBackground,
.circleProgress {
  fill: none;
}

.circleBackground {
  stroke: #4775FE;
}

.circleProgress {
  stroke: #FA5D54;
}

.percentageText {
  margin-bottom: 10px;
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #A6AEC5;
}

