.link {
    padding: 0;
    margin: 0;
    font-family: Roboto, sans-serif;
    font-size: 14px;
    line-height: 16px;
    text-decoration-line: underline;
    color: #4775FE;
    cursor: pointer;
    opacity: 1;
    transition: .4s opacity linear;
}

.link:hover {
    opacity: .6;
}