.label {
    width: 100%;
    font-family: Roboto, sans-serif;
    font-weight: 500;
}

.field {
    position: relative;
    width: 100%;
    height: 40px;
    padding: 0 15px;
    font-size: 14px;
    line-height: 16px;
    box-shadow: 0px 10px 60px rgba(0, 0, 0, 0.1);
    border: 1px solid transparent;
    border-radius: 40px;
    outline: transparent;
    transition: .4s border-color linear;
}

.field.error {
    border-color: #FA5D54;
}

.name {
    margin-bottom: 5px;
    font-size: 13px;
    line-height: 15px;
    color: #A6AEC5;
    transition: .4s color linear;
}

.name.errorName {
    color: #FA5D54;
}

.info {
    padding: 5px 10px;
    background-color: #EBEEF6;
    border-radius: 20px;
}

.serchList {
    position: absolute;
    top: 65%;
    left: 0;
    width: 100%;
    max-height: 200px;
    overflow: hidden;
    overflow-y: auto;
    padding-bottom: 10px;
    padding-top: 25px;
    margin: 0;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.3);
    background-color: #FFF;
    border-radius: 0 0 10px 10px;
}

.serchList .serchListItem {
    width: 100%;
    padding: 0 15px;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    opacity: 1;
    transition: .4s opacity linear;
    cursor: pointer;
}

.serchList .serchListItem:not(:last-child) {
    padding-bottom: 10px;
}

.serchList .serchListItem:hover {
    opacity: .5;
}