.label {
    width: 100%;
}

.textarea {
    width: 100%;
    height: 100%;
    padding: 15px;
    font-family: Roboto, sans-serif;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #131313;
    background: #FFFFFF;
    box-shadow: 0px 10px 60px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    border: 1px solid transparent;
    resize: none;
    transition: .4s border-color linear;
}

.textarea.error {
    border-color: #FA5D54;
}