.text, .link {
    padding: 0;
    margin: 0;
    font-family: Roboto;
    font-weight: 500;
    font-size: 13px;
    line-height: 15px;
    color: #131313;
    text-decoration: none;
}

.link:hover {
    color: #504d4d;
}