.tagInputBlock {
    position: relative;
    width: 100%;
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
}

.tagInputTitle {
    margin-bottom: 5px;
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    font-size: 13px;
    line-height: 15px;
    color: #A6AEC5;
}

.tagInput {
    position: relative;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-flow: row;
    width: 100%;
    height: 40px;
    padding: 5px 10px 0;
    background-color: #FFF;
    box-shadow: 0px 10px 60px rgba(0, 0, 0, 0.1);
    border-radius: 40px;
    border: 1px solid transparent;
    transition: .4s border linear;
    overflow-x: auto;
    overflow-y: hidden;
}

.tagInput.error {
    border-color: #FA5D54;
}

.tagTextInput {
    width: 100px;
    padding: 5px 10px;
    margin-bottom: 5px;
    border: none;
    outline: none;
}

.tagItem {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 25px;
    padding: 5px 10px;
    margin-right: 10px;
    margin-bottom: 5px;
    white-space: nowrap;
    color: #FFF;
    background-color: #4775FE;
    border-radius: 25px;
}

.closeBtnBlock {
    margin-left: 5px;
    transform: rotate(-45deg);
}

.serchListTags {
    position: absolute;
    top: 65%;
    left: 0;
    z-index: 0;
    width: 100%;
    max-height: 200px;
    overflow: hidden;
    overflow-y: auto;
    padding-bottom: 10px;
    padding-top: 25px;
    margin: 0;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.3);
    background-color: #FFF;
    border-radius: 0 0 10px 10px;
}

.serchListTags .serchListItem {
    width: 100%;
    padding: 0 10px;
    font-size: 13px;
    line-height: 15px;
    opacity: 1;
    transition: .4s opacity linear;
    cursor: pointer;
}

.serchListTags .serchListItem:not(:last-child) {
    margin-bottom: 10px;
}

.serchListTags .serchListItem:hover {
    opacity: .7;
}

.serchListTags::-webkit-scrollbar {
    width: 0px;
    height: 0px;
}
 
.serchListTags::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0.2);
}
 
.serchListTags::-webkit-scrollbar-thumb {
    background: rgba(90, 90, 90);
}