.status, 
.statusButton {
    font-family: 'Roboto';
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #A6AEC5;
}

.statusButton {
    text-decoration: underline;
    border: 1px solid transparent;
    background-color: transparent;
    cursor: pointer;
}

.status.active, 
.statusButton.active {
    color: #4775FE;
}

.status.archive,
.statusButton.archive {
    color: #FA5D54;
}