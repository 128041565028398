.btn {
    padding: 0;
    margin: 0;
    border: 1px solid transparent;
    outline: transparent;
    background-color: transparent;
    cursor: pointer;
    opacity: 1;
    transition: .4s opacity ease-in;
}

.btn:hover {
    opacity: .6;
}