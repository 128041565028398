.rectProgressBlock {
    display: flex;
    align-items: center;
    width: 100%;
}

.rectProgressBlock span {
    margin-right: 15px;
}

.backProgress {
    width: 140px;
    height: 10px;
    background-color: #EBEEF6;
    border-radius: 20px;
}

.progress {
    height: 100%;
    background-color: #4775FE;
    border-radius: 20px;
}