.dataList {
    width: 100%;
}

.dataList:not(:last-of-type) {
    margin-bottom: 30px;
}

.activeList, 
.archiveList {
    list-style: none;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
}

.btn {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-family: Roboto, sans-serif;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    color: #A6AEC5;
    border: none;
    outline: transparent;
    background-color: transparent;
}

.iconBtn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 15px;
    height: 15px;
    margin-right: 15px;
    transition: .4s transform linear;
}

.iconBtn.active {
    transform: rotate(-180deg);
}

.groupName {
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #131313;
}

.srviceName {
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    font-size: 13px;
    line-height: 15px;
    color: #A6AEC5;
}

.status {
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #4775FE;
}

.status.archive {
    color: #FA5D54;
}

.date {
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    font-size: 13px;
    line-height: 15px;
    color: #131313;
}

.circel {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background-color: #4775FE;
}

.circel.archive {
    background-color: #A6AEC5;
} 

.dataItem {
    display: flex;
    width: 100%;
    margin-bottom: 15px;
}
