.file-finder-enter {
    opacity: 0;
  }
  .file-finder-enter-active {
    opacity: 1;
    transition: .5s opacity linear;
  }
  .file-finder-exit {
    opacity: 1;
  }
  .file-finder-exit-active {
    opacity: 0;
    transition: .5s opacity linear;
  }