.dataUser {
    display: flex;
    width: 100%;
}

.leftCol {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    height: 100%;
    margin-right: 15px;
}

.rightCol {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}

.name {
    margin-bottom: 5px;
    font-family: Roboto, sans-serif;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    color: #131313;
}

.belonging {
    margin-bottom: 10px;
    font-family: Roboto, sans-serif;
    font-weight: 500;
    font-size: 13px;
    line-height: 15px;
    color: #A6AEC5;
}