.dropdownBlock {
    position: relative;
    display: flex;
    justify-content: flex-start;
    min-height: 40px;
    font-family: Roboto, sans-serif;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #131313;
}

.dropdown {
    position: relative;
    display: flex;
    align-items: center;
    min-height: 40px;
    padding: 0 15px;
    border: 1px solid transparent;
    box-shadow: 0px 10px 60px rgba(0, 0, 0, 0.1);
    background-color: #FFF;
    cursor: pointer;
}

.dropdown.transparent {
    box-shadow: 0px 10px 60px rgba(0, 0, 0, 0.0);
    background-color: transparent;
}

.name {
    display: block;
    margin-right: 10px;
}

.dropdown.error {
    border-color: #FA5D54;
}

.iconBlock {
    width: 30px; 
    height: 30px;
    margin-right: 10px;
    border-radius: 50%;
}

.iconArrow {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-left: auto;
    margin-top: 3px;
    transform: rotate(0);
    transition: .4s transform linear;
}

.iconArrow.active {
    transform: rotate(-180deg);
}

.dropdown.icon {
    padding-left: 5px;
}

.dropdownList {
    position: absolute;
    top: 70%;
    left: 0;
    max-height: 265px;
    padding-bottom: 10px;
    padding-top: 30px;
    margin: 0;
    overflow: hidden;
    overflow-y: auto;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.3);
    background-color: #FFF;
}

.dropdownList.transparent {
    padding-top: 15px;
    
}

.dropdownList .dropdownItem {
    width: 100%;
    padding: 0 10px;
    opacity: 1;
    transition: .4s opacity linear;
    cursor: pointer;
}

.dropdownList .dropdownItem:not(:last-child) {
    margin-bottom: 10px;
}

.dropdownList .dropdownItem:hover {
    opacity: .7;
}

.infoBlock {
    font-size: 13px;
    line-height: 15px;
    color: #A6AEC5;
}

.infoBlock > .value {
    color: #131313;
}

.dropdown.doubleClick {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}